import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Hero from './components/Hero';
import Features from './components/Features';
import Footer from './components/Footer';
import HowItWorks from './components/HowItWorks';
import JoinNowForm from './components/JoinNowForm'; // Import the form component

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/join" element={<JoinNowForm />} /> {/* Route to Join Now form */}
      </Routes>
    </Router>
  );
}

const HomePage = () => (
  <div>
    <Hero />
    <Features />
    <HowItWorks />
    <Footer />
  </div>
);

export default App;
