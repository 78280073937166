import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { motion } from 'framer-motion';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const featureData = [
  {
    title: 'Professional Networking',
    description: 'Connect with peers,Like minded people and professionals.',
    icon: <BusinessCenterIcon sx={{ fontSize: '3rem', color: '#007ACC' }} />,
  },
  {
    title: 'Career Growth',
    description: 'Gain insights, guidance, and opportunities to advance your career.',
    icon: <TrendingUpIcon sx={{ fontSize: '3rem', color: '#32CD32' }} />,
  },
  {
    title: 'Collaboration',
    description: 'Work with like-minded professionals and students.',
    icon: <GroupWorkIcon sx={{ fontSize: '3rem', color: '#FFD700' }} />,
  },
  {
    title: 'Find Nearby Like-Minded People',
    description: 'Locate professionals, peers near you and start networking.',
    icon: <LocationOnIcon sx={{ fontSize: '3rem', color: '#FF5733' }} />,
  },
];

const Features = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #f7f7f7 0%, #eaeaea 100%)',
        py: 8,
        color: '#333333',
        textAlign: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat', mb: 6 }}>
          Our Key Features
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {featureData.map((feature, index) => (
            <Grid item xs={12} sm={6} md={3} key={index} sx={{ textAlign: 'center' }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                viewport={{ once: true }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    padding: 4,
                    backgroundColor: '#ffffff',
                    borderRadius: '16px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.05)',
                    },
                  }}
                >
                  {/* Icon for the feature */}
                  <Box sx={{ mb: 3 }}>{feature.icon}</Box>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      fontFamily: 'Montserrat',
                      fontSize: '1.25rem',
                    }}
                  >
                    {feature.title}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 2,
                      fontFamily: 'Open Sans',
                      fontSize: '1rem',
                      color: '#666666',
                    }}
                  >
                    {feature.description}
                  </Typography>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default Features;
