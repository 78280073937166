// Import Firebase libraries
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Your Firebase configuration (replace with your Firebase config details)
  const firebaseConfig = {
    apiKey: "AIzaSyAiu3bZi5tQx1hBDTR_eY4bp06IjCsnCL8",
    authDomain: "hiveup-77c27.firebaseapp.com",
    projectId: "hiveup-77c27",
    storageBucket: "hiveup-77c27.appspot.com",
    messagingSenderId: "982514960008",
    appId: "1:982514960008:web:821f872e1139e21390413d",
    measurementId: "G-5KDR1PGBR0"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Firestore
export const auth = getAuth(app);
export const db = getFirestore(app);
