import React, { useState } from 'react';
import { Container, Typography, TextField, Button, FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, Box } from '@mui/material';
import { setDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import { motion } from 'framer-motion'; // For animation
import HowItWorks from './HowItWorks'; // Import How It Works section
import Features from './Features'; // Import Key Features section

const JoinNowForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
  });

  const [isSubmitted, setIsSubmitted] = useState(false); // Track form submission

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Store user information in Firestore
      await setDoc(doc(db, 'waitlist', formData.email), {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        role: formData.role,
        status: 'waitlisted',
      });

      // Display success message
      setIsSubmitted(true);
    } catch (error) {
      console.error('Error adding user to waitlist:', error.message);
      alert('There was an error. Please try again.');
    }
  };

  if (isSubmitted) {
    return (
      <Container maxWidth="lg" sx={{ mt: 8 }}>
        <Typography variant="h5" align="center" sx={{ mb: 4 }}>
          Waitlisted....
          We will send you an email soon!
        </Typography>
        <Typography variant="body1" align="center" sx={{ mb: 4 }}>
          Meanwhile, check out our <a href="https://www.instagram.com/hiveup.club?igsh=MWZweDAxNGJvcTJyOQ==" target="_blank" rel="noopener noreferrer">Instagram</a> for the latest updates!
        </Typography>
        
        {/* Display How It Works and Key Features sections */}
        <HowItWorks />
        <Features />
      </Container>
    );
  }

  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 1 }}
    >
      <Box
        sx={{
          background: 'linear-gradient(180deg, #FFD700, #FFEC8B)',
          minHeight: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          py: 8,
        }}
      >
        <Container maxWidth="sm">
          <Typography variant="h4" sx={{ textAlign: 'center', mb: 4 }}>
            Join Waitlist for HiveUp.club
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Name"
              name="name"
              fullWidth
              required
              value={formData.name}
              onChange={handleChange}
              sx={{ mb: 3 }}
            />
            <TextField
              label="Email"
              name="email"
              fullWidth
              required
              type="email"
              value={formData.email}
              onChange={handleChange}
              sx={{ mb: 3 }}
            />
            <TextField
              label="Phone (Optional)"
              name="phone"
              fullWidth
              type="tel"
              value={formData.phone}
              onChange={handleChange}
              sx={{ mb: 3 }}
            />
            <FormControl component="fieldset" sx={{ mb: 3 }}>
              <FormLabel component="legend">Are you a:</FormLabel>
              <RadioGroup
                name="role"
                value={formData.role}
                onChange={handleChange}
                row
              >
                <FormControlLabel value="student" control={<Radio />} label="Student" />
                <FormControlLabel value="professional" control={<Radio />} label="Working Professional" />
                <FormControlLabel value="business" control={<Radio />} label="Business Owner" />
              </RadioGroup>
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: '#000000',
                color: '#FFFFFF',
                fontWeight: 'bold',
                fontSize: '1.1rem',
                py: 2,
                '&:hover': {
                  backgroundColor: '#333333',
                  transition: 'all 0.3s ease-in-out',
                },
              }}
            >
              Join Now
            </Button>
          </form>
        </Container>
      </Box>
    </motion.div>
  );
};

export default JoinNowForm;
