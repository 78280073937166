import React from 'react';
import { Typography, Container, Button, Box } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation

const Hero = () => {
  const navigate = useNavigate(); // Hook to navigate between routes

  const handleJoinNowClick = () => {
    navigate('/join'); // Navigate to the Join Now form when clicked
  };

  return (
    <Box
      sx={{
        background: 'linear-gradient(180deg, #FFD700, #FFEC8B)',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#333333',
        textAlign: 'center',
        position: 'relative',
      }}
    >
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          <Typography
            variant="h1"
            sx={{
              fontWeight: 'bold',
              fontFamily: 'Montserrat',
              fontSize: { xs: '3rem', sm: '5rem', md: '6rem' },
              lineHeight: 1.2,
            }}
          >
            HiveUp.club
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1.5 }}
        >
          <Typography
            variant="h6"
            sx={{
              fontFamily: 'Open Sans',
              mb: 4,
              fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' },
            }}
          >
            Connect. Collaborate. Grow.
          </Typography>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.5, duration: 0.8 }}
        >
          <Button
            onClick={handleJoinNowClick} // Handle navigation on button click
            variant="contained"
            size="large"
            sx={{
              backgroundColor: '#000000',
              color: '#FFFFFF',
              fontWeight: 'bold',
              fontFamily: 'Poppins',
              '&:hover': {
                backgroundColor: '#444444',
              },
              mt: 4,
              padding: '10px 20px',
            }}
          >
            Join Now
          </Button>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Hero;
