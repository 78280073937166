import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import { motion } from 'framer-motion';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SwipeRightIcon from '@mui/icons-material/SwipeRight';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

const steps = [
  {
    number: 1,
    title: 'Sign Up',
    description: 'Create an account with your email to get started.',
    icon: <PersonIcon sx={{ fontSize: '3rem', color: '#007ACC' }} />, // Consistent color with slight variation
  },
  {
    number: 2,
    title: 'Build Your Profile',
    description: 'Add your professional details, skills.',
    icon: <WorkIcon sx={{ fontSize: '3rem', color: '#32CD32' }} />,
  },
  {
    number: 3,
    title: 'Find Nearby People',
    description: 'Locate professionals, mentors, or peers near you and start networking.',
    icon: <LocationOnIcon sx={{ fontSize: '3rem', color: '#FFD700' }} />,
  },
  {
    number: 4,
    title: 'Swipe to Connect',
    description: 'Swipe right to connect with professionals or left to skip.',
    icon: <SwipeRightIcon sx={{ fontSize: '3rem', color: '#007ACC' }} />,
  },
  {
    number: 5,
    title: 'Grow Your Network',
    description: 'Engage with your connections and unlock new career opportunities.',
    icon: <GroupAddIcon sx={{ fontSize: '3rem', color: '#32CD32' }} />,
  },
];

const HowItWorks = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #f7f7f7 0%, #eaeaea 100%)', // Soft background
        py: 8,
        color: '#333333',
        textAlign: 'center',
      }}
    >
      <Container maxWidth="lg">
        <Typography variant="h4" sx={{ fontWeight: 'bold', fontFamily: 'Montserrat', mb: 6 }}>
          How It Works
        </Typography>
        <Grid container spacing={6} justifyContent="center"> {/* Added more spacing */}
          {steps.map((step, index) => (
            <Grid item xs={12} sm={6} md={2} key={index} sx={{ textAlign: 'center' }}>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: index * 0.2 }}
                viewport={{ once: true }}
              >
                <Box
                  sx={{
                    textAlign: 'center',
                    padding: 4,
                    backgroundColor: '#ffffff',
                    borderRadius: '16px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    mb: 4, // Increased margin-bottom for more spacing
                  }}
                >
                  {/* Step Number */}
                  <Typography
                    variant="h5"
                    sx={{
                      fontWeight: 'bold',
                      fontFamily: 'Montserrat',
                      color: '#FF5733', // Highlight step number in a standout color
                      mb: 2,
                    }}
                  >
                    {step.number}
                  </Typography>

                  {/* Icon for the step */}
                  <Box sx={{ mb: 3 }}>{step.icon}</Box>

                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      fontFamily: 'Montserrat',
                      fontSize: '1.25rem',
                    }}
                  >
                    {step.title}
                  </Typography>

                  <Typography
                    sx={{
                      mt: 2,
                      fontFamily: 'Open Sans',
                      fontSize: '1rem',
                      color: '#666666',
                    }}
                  >
                    {step.description}
                  </Typography>
                </Box>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HowItWorks;
