import React from 'react';
import { Box, Typography, Container, IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#1a1a1a', py: 4, backgroundImage: 'linear-gradient(to top, #333333, #1a1a1a)' }}>
      <Container maxWidth="lg" sx={{ textAlign: 'center', color: '#FFFFFF' }}>
        <Typography variant="body2" sx={{ fontFamily: 'Open Sans' }}>
          © 2024 HiveUp. All rights reserved.
        </Typography>
        
        {/* Instagram Link */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Follow us on Instagram:
          </Typography>
          <IconButton
            href="https://www.instagram.com/hiveup.club?igsh=MWZweDAxNGJvcTJyOQ=="
            target="_blank"
            sx={{ color: '#FFFFFF' }}
          >
            <InstagramIcon fontSize="large" />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
